/**
 * Lodash. Eases Objects maniuplations. https://lodash.com/ . https://en.wikipedia.org/wiki/Lodash
 */
window._ = require('lodash');

/**
 * jQuery. https://jquery.com/
 */
try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) {}

/**
 * Http client library . https://github.com/axios/axios
 */
window.axios = require('axios');
/**
 * Local storage. https://github.com/bevacqua/local-storage
 */
window.ls = require('local-storage');

/**
 * Configuration
 */
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Getting tokens from PHP Generated Html
 */
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
if(!token) console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
let access_token = document.head.querySelector('meta[name="access-token"]');
if (access_token) {
    window.axios.defaults.headers.common['Authorization'] = "Bearer "  + access_token.content;
}
if(!access_token) console.log('No access token found. Probably you Won\'t be able to use api calls');

/**
 * Setting up locales persistance
 */
window.ls = require('local-storage');

